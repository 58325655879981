(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/helper/helpers/handlebars-helpers.js') >= 0) return;  svs.modules.push('/components/lb-ui/helper/helpers/handlebars-helpers.js');

var isServerSide = typeof exports === 'object',
  componentHelper;

if (isServerSide) {
  var trinidad = require('trinidad-core').core;
  componentHelper = trinidad.components.require('utils', 'component-helper').api;

  registerHelpers(require('hbs'));
} else {
  componentHelper = svs.utils.component_helper.helper;
  registerHelpers(Handlebars);
}

function registerHelpers(hbs) {

  hbs.registerHelper('lb-ui', function() {
    return componentHelper.apply(this, [arguments, isServerSide ? 'lb_ui' : svs.lb_ui]);
  });
}


 })(window);